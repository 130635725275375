import Vue from 'vue'
import App from './productWidget.vue'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { VLazyImagePlugin } from "v-lazy-image";

Vue.use(VLazyImagePlugin);

library.add(faArrowLeft)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

window.products = [];
function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
window.axios = require('axios');

window.axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

document.addEventListener('DOMContentLoaded', function (event) {
  if ('IntersectionObserver' in window) {
    // Create new IntersectionObserver
    const io = new IntersectionObserver(function (el) {
      // Element enters the viewport
      el.forEach(function (entry) {
        if (entry.intersectionRatio !== 0) {
          axios.get(basedir + '/ajaxRequestServlet?mode=GetProduct&isSpecial=true&payload={productID: ' + entry.target.attributes.getNamedItem('id').value + ', isPointShop:' + (isPointShop == "Y") + '}').then(response => {
            if ((response.data.products[0].productPageLink != '' && typeof response.data.products[0].productPageLink != 'undefined')) {
              window.products.push({
                id: response.data.products[0].productId, instance: new Vue({
                  name: response.data.products[0].name.trim(),
                  data: {
                    customText: entry.target.attributes.getNamedItem('customtext') != null ? entry.target.attributes.getNamedItem('customtext').value : '',
                    customimage: entry.target.attributes.getNamedItem('customimage') != null ? entry.target.attributes.getNamedItem('customimage').value : '',
                    backorder: backorder,
                    preorder: preorder,
                    frontpage: document.getElementsByClassName('frontpageproducts').length > 0,
                    container: entry.target.parentElement != null ? entry.target.parentElement.className == 'recommendedProducts' || entry.target.parentElement.className == 'frontpageproducts' ? entry.target.parentElement.className : false : false,
                    customimgurl: entry.target.attributes.getNamedItem('customimgurl') != null ? entry.target.attributes.getNamedItem('customimgurl').value : 'undefiend',
                    product: response.data.products[0],
                    pointshop: (isPointShop == "Y"),
                    pointshopUrlPrefix: pointshopPrefix + "/",
                    iscompany: (userIsCompany == "Y"), 
                    display: displaySettingsForWidget,
                    test: typeof widgetTranslations == 'object',
                    language: (typeof widgetTranslations == 'object' ? widgetTranslations : JSON.parse(widgetTranslations)),
                    isNutriverse:  document.body.classList.contains('nutriverse')? true:false
                  },
                  render: h => h(App),
                }).$mount(entry.target)
              })
            } else {
              el[0].target.remove()
            }
          })
        }
      })
    });
    // Start observing all elements with a data-src attribute
    var elements = document.querySelectorAll('.productWidget');
    Array.from(elements).forEach(function (el) {
      io.observe(el);
    });
  } else {
    document.getElementsByClassName("productWidget").forEach(function (entry) {
      axios.get(basedir + '/ajaxRequestServlet?mode=GetProduct&isSpecial=true&payload={productID: ' + entry.getAttribute('id') + ', isPointShop:' + (isPointShop == "Y") + '}').then(response => {
        if ((response.data.products[0].productPageLink != '' && typeof response.data.products[0].productPageLink != 'undefined')) {
          window.products.push(new Vue({
            data: {
              customText: entry.getAttribute('customtext') != null ? entry.getAttribute('customtext') : '',
              customimage: entry.getAttribute('customimage') != null ? entry.getAttribute('customimage') : '',
              backorder: backorder,
              pointshop: (isPointShop == "Y"),
              frontpage: document.getElementsByClassName('frontpageproducts').length > 0,
              pointshopUrlPrefix: pointshopPrefix + "/",
              preorder: preorder,
              container: entry.parentElement != null ? entry.parentElement.className == 'recommendedProducts' || entry.parentElement.className == 'frontpageproducts' ? entry.parentElement.className : false : false,
              customimgurl: entry.getAttribute('customimgurl') != null ? entry.getAttribute('customimgurl') : 'undefiend',
              product: response.data.products[0],
              iscompany: (userIsCompany == "Y"),
              test: typeof widgetTranslations == 'object',
              display: displaySettingsForWidget,
              language: (typeof widgetTranslations == 'object' ? widgetTranslations : JSON.parse(widgetTranslations)),
              isNutriverse:  document.body.classList.contains('nutriverse')? true:false
            },
            render: h => h(App),
          }).$mount(entry))
        } else {
          el[0].target.remove()
        }
      })
    });
  }
});
window["initRecommendedProducts"] = function initRecommendedProducts() {
  document.querySelectorAll(".recommendedProducts .productWidget").forEach(function (entry, index) {
    axios.get(basedir + '/ajaxRequestServlet?mode=GetProduct&isSpecial=true&payload={productID: ' + entry.getAttribute('id') + ', isPointShop:' + (isPointShop == "Y") + '}').then(response => {
      if ((response.data.products[0].productPageLink != '' && typeof response.data.products[0].productPageLink != 'undefined')) {
        window.products.push(new Vue({
          data: {
            customText: entry.getAttribute('customtext') != null ? entry.getAttribute('customtext') : '',
            customimage: entry.getAttribute('customimage') != null ? entry.getAttribute('customimage') : '',
            backorder: backorder,
            pointshop: (isPointShop == "Y"),
            pointshopUrlPrefix: pointshopPrefix + "/",
            preorder: preorder,
            container: entry.parentElement != null ? entry.parentElement.className == 'recommendedProducts' || entry.parentElement.className == 'frontpageproducts' ? entry.parentElement.className : false : false,
            customimgurl: entry.getAttribute('customimgurl') != null ? entry.getAttribute('customimgurl') : 'undefiend',
            product: response.data.products[0],
            iscompany: (userIsCompany == "Y"),
            test: typeof widgetTranslations == 'object',
            display: displaySettingsForWidget,
            language: (typeof widgetTranslations == 'object' ? widgetTranslations : JSON.parse(widgetTranslations)),
            isNutriverse:  document.body.classList.contains('nutriverse')? true:false
          },
          render: h => h(App),
        }).$mount(entry))
      } else {
        entry.remove()
      }
    })
  });
}
/* 
if(document.querySelectorAll(".recommendedProducts .productWidget").length > 0) {
var prodWidgetInterval;
var timeGone = 0;
  prodWidgetInterval = setInterval(() => {
    timeGone++
    if(timeGone < 100) {
      document.querySelectorAll(".recommendedProducts .productWidget").forEach(function (entry, index) {
        axios.get(basedir + '/ajaxRequestServlet?mode=GetProduct&payload={productID: ' + entry.getAttribute('id') + ', isPointShop:'+(isPointShop == "Y")+'}').then(response => {
          if(response.data.price != 0) {
          window.products.push(new Vue({
            data: {
              customText: entry.getAttribute('customtext') != null ? entry.getAttribute('customtext') : '',
              customimage: entry.getAttribute('customimage') != null ? entry.getAttribute('customimage') : '',
              backorder: backorder,
              pointshop: (isPointShop == "Y"),
              pointshopUrlPrefix: pointshopPrefix+"/",
              preorder: preorder,
              container: entry.parentElement!= null ? entry.parentElement.className == 'recommendedProducts' || entry.parentElement.className == 'frontpageproducts' ? entry.parentElement.className : false : false,
              customimgurl: entry.getAttribute('customimgurl') != null ? entry.getAttribute('customimgurl') : 'undefiend',
              product: response.data,
              iscompany: (userIsCompany == "Y"),
              display: displaySettingsForWidget,
              language: JSON.parse(decodeHtml(JSON.stringify(cws(languageWSM))))
            },
            render: h => h(App),
          }).$mount(entry))
        }
        })
        if(document.querySelectorAll(".recommendedProducts .productWidget").length == (index+1)) {
          clearInterval(prodWidgetInterval)
        }
      });
    } else {
      clearInterval(prodWidgetInterval)
    }
  }, 100)
} */


//polyfill
if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    'use strict';

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    }
    if (start === undefined) { start = 0; }
    return this.indexOf(search, start) !== -1;
  };
}